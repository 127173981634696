import React from 'react'
import Contacts from './contacts'
import TypeWriter from './typewriter'

const typewriter_lines = [
    "a Senior at Brown.",
    "a Coder.",
    "a Reader.",
    "a Birdwatcher.",
    "a Learner.",
]

function About() {
    return (
        <div id="about" className="about">
            <div className="welcome-section">
                <h1 className="greeting-header"><span className="span-h">H</span><span className="span-e">e</span><span className="span-l">l</span><span className="span-l2">l</span><span className="span-o">o</span>. <br></br>My name is <span className="span-1">S</span><span className="span-2">a</span><span className="span-3">i</span>.</h1>
                <TypeWriter precursor="I'm " entries={typewriter_lines}/>
            </div>
            <div className="contact-section">
                <p className="email-text">Reach out: <a href="mailto:sai_siddhi_karnati@brown.edu">sai_siddhi_karnati@brown.edu</a></p>            
                <Contacts/>
            </div>
        </div>
    );
}

export default About;