import React from 'react'
import ExperienceTree from './experiencetree'
import Skills from './skills'

function Experience() {
    return (
        <div id="experience" className="experience">
            <h1 className="experience-header">Experience</h1>
            <ExperienceTree/>
        </div>
    );
}

export default Experience;