import { React, Component } from 'react'

class TypeWriter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displayText: this.props.entries[0],
            currIndex: 0,
            mode: "delete"
        }
        this.changeText = this.changeText.bind(this)
        this.incrementIndex = this.incrementIndex.bind(this)
        this.changeMode = this.changeMode.bind(this)
        this.updateWriter = this.updateWriter.bind(this)
    }

    componentDidMount() {
        this.timer = setInterval(this.updateWriter, 165)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    changeText(displayText) {
        this.setState({displayText})
    }

    incrementIndex() {
        const { entries } = this.props
        const entries_length = entries.length
        const { currIndex } = this.state

        if (currIndex == entries_length - 1) {
            this.setState({
                currIndex: 0
            })
        } else {
            this.setState({
                currIndex: currIndex + 1
            })
        }
    }

    changeMode(mode) {
        this.setState({mode})
    }

    updateWriter() {
        const { entries } = this.props
        const { displayText, currIndex, mode } = this.state
        const displayLength = displayText.length

        if (mode == "delete") {
            if (displayLength == 1) {
                this.changeText("")
                this.incrementIndex()
                this.changeMode("insert")
            } else {
                this.changeText(displayText.substring(0, displayLength - 1))
            }
        } else {
            const currWord = entries[currIndex]

            if (displayLength == currWord.length) {
                this.changeMode("delete")
            } else {
                this.changeText(currWord.substring(0, displayLength + 2))
            }
        }
    }

    render() {
        const { precursor } = this.props
        const { displayText } = this.state
        return (
            <div className="typewriter-container">
                <h1 className="typewriter-text">{precursor}{displayText}</h1>
            </div>
        );
    }
}

export default TypeWriter
