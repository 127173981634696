import React from 'react'
import ProjectPane from './projectpane'

const projects = [
    {
        proj_img: "./images/project-images/weenix-image.png",
        title: "Weenix OS",
        languages: ["C"],
        description: "Worked on a compact version of Unix with its own thread managaement system, drivers, a virtual file system, and virtual memory management."
    },
    {
        proj_img: "./images/project-images/heatmap-image.png",
        title: "heatMap",
        languages: ["React", "TypeScript"],
        description: "Created a React web application to provide live ratings and wait-time information about Brown’s dining halls, using data from real-time student surveys."
    },
    {
        proj_img: "./images/project-images/somos-image.png",
        title: "SOMOS Magazine Website",
        languages: ["HTML", "JavaScript", "Hugo"],
        description: "Created a website for a Latinx literary magazine at Brown with a fully-functioning blog and their archived issues integrated on the site."
    },
    {
        proj_img: "./images/project-images/shell-image.png",
        title: "Shell",
        languages: ["C"],
        description: "Created a command shell program that can parse an input, create and navigate file directories, remove files, and link files."
    }
]

function Projects() {
    return (
        <div id="projects" className="projects">
            <h1 className="projects-header">Projects</h1>
            <div className="projects-grid">
            {projects.map((proj, i) =>
                <ProjectPane key={i} project={proj}/>
            )}
            </div>
        </div>
    );
}

export default Projects;