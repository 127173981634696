import { React, Component } from 'react'

class ProjectPane extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { project } = this.props
        const { proj_img, title, languages, description } = project
        return (
            <div className="project-pane">
                <img className="project-image" src={proj_img} alt={title}></img>

                <div className="text-bottom">
                    <p className="project-title">{title}</p>
                    <div className="tools-container">
                        {languages.map((language, i) =>
                        <p className="tools-text" key={i}>{language}</p>
                        )}
                    </div>
                    <p className="project-description">{description}</p>
                </div>
            </div>
        );
    }
}

export default ProjectPane;