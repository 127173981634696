import React from 'react'
import BubbleContainer from './bubblecontainer'

const skills = [
    {
        title: "Languages",
        contents: ["Java", "C", "C++", "Python", "HTML", "CSS", "JavaScript", 
                   "Common Lisp", "ReasonML", "Racket"]
    },
    {
        title: "Front-End & Back-End",
        contents: ["React", "MongoDB", "JQuery", "SQL", "Express", "Node.js", "REST"]
    },
    {
        title: "Other Tools",
        contents: ["Git", "Figma", "Photoshop", "Premier", "Microsoft Suite", "Atlassian Suite"]
    },
    {
        title: "(Actual) Languages",
        contents: ["English (Native Fluency)", "Spanish (Working Proficiency)",
                   "Telugu (Elementary Profienciency)", "Russian (Working Proficiency"]
    }
]

function Skills() {
    return (
        <div className="skills">
            <h1 className="skills-header">Skills</h1>
            {skills.map((d, i) =>
                <BubbleContainer key={i} data={d}></BubbleContainer>
            )}
        </div>
    );
}

export default Skills;