import React from 'react'

function Contacts() {
    return (
        <div className="contacts">
            <a target="_blank" href="https://www.linkedin.com/in/sai-karnati/">
                <img className="social-image" src="./images/social-images/linkedin.png" alt="LinkedIn"></img>
            </a>
            <a target="_blank" href="https://www.instagram.com/skarnati20/" alt="Instagram">
                <img className="social-image" src="./images/social-images/instagram.png"></img>
            </a>
            <a target="_blank" href="https://github.com/skarnati20" alt="GitHub">
                <img className="social-image" src="./images/social-images/github.png"></img>
            </a>
        </div>
    )
}

export default Contacts;