import { React, Component } from 'react'

class ExperienceNode extends Component {
    render() {
        const { experience } = this.props
        const { img_path, company, company_website, 
            role, location, dates, bullets } = experience
        return (
            /* Need to configure for both desktop and mobile*/
            <div className="experience-node">
                <a className="image-link" rel="noreferrer" target="_blank" href={company_website}><img className="company-image" src={img_path} alt={company}></img></a>
                <div className="description-panel">
                    <p className="role-text">{role}</p>
                    <p className="company-text">{company}</p>
                    <p className="dates-text">{location} &nbsp; — &nbsp; {dates}</p>
                    <div className="description-box">
                        {bullets.map((bullet, i) =>
                            <li key={i} className="description-text">{bullet}</li>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ExperienceNode;