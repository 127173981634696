import { React, Component } from 'react'
import ExperienceNode from './experiencenode'

const experiences = [
    {
        img_path: "./images/company-images/cognex-logo.png",
        company: "Cognex Corporation",
        company_website: "https://www.cognex.com/",
        role: "Software Development Intern",
        location: "Natick, MA",
        dates: "Jun - Aug 2023",
        bullets: [
            "Implemented APIs and unit tests for Cognex’s vision tools and libraries, the leading ones in the machine vision industry",
            "Delivered C++ tools to satisfy customers’ image processing needs. Worked on Python bindings to expand their scope",
            "Identified areas where Cognex’s core libraries could cut technical overhead and improve its object-oriented design",
            "Communicated progress with team members in daily standups and assembled proposals to create more agile development"
        ]
    },
    {
        img_path: "./images/company-images/csu-logo.jpeg",
        company: "Intelligent Communications & Autonomous System Lab @ CSU",
        company_website: "https://eecs.csuohio.edu/~rahmati/research.html",
        role: "Summer Researcher",
        location: "Cleveland, OH",
        dates: "Jun - Aug 2022",
        bullets: [
            "Assembled a remotely operated vehicle for underwater exploration and algae sampling with Blue Robotics’ BlueROV2", 
            "Programmed a Python script to stabilize the device’s motion against currents, and facilitated the robot’s pool simulations",
            "Designed an algorithm to sample a region of water. Achieved 80% yield and reduced required movement commands by 70%"   
        ]
    },
    {
        img_path: "./images/company-images/brown-logo.png",
        company: "AI Lab @ Brown",
        company_website: "https://health-nlp.com/index.html",
        role: "Undergraduate Assistant",
        location: "Providence, RI",
        dates: "Sept - Dec 2021",
        bullets: [
            "Worked on a natural language processing (NLP) model to simplify and generate informed consent forms for medical studies", 
            "Compiled and parsed through research studies to supply representative data to fine-tune a BART transformers model", 
            "Communicated in bi-weekly meetings with a postdoctoral mentor, learning about NLP and showcasing code demos"
        ]
    }
]

class ExperienceTree extends Component {
    render() {
        return (
            <div className="experience-tree">
                {experiences.map((exp, i) =>
                    <ExperienceNode key={i} experience={exp}/>
                )}
            </div>
        );
    }
}

export default ExperienceTree;
