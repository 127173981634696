import React from 'react'
import Navigation from './components/navigation'
import About from './components/about'
import Experience from './components/experience'
import Skills from './components/skills'
import Projects from './components/projects'

function App() {
  return (
    <div className="App">
      <Navigation/>
      <About/>
      <Experience/>
      <Skills/>
      <Projects/>
    </div>
  );
}

export default App;
