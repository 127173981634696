import { React, Component } from 'react'

class BubbleContainer extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { data } = this.props
        const { title, contents } = data
        return (
            <div className="bubble-container">
                <p className="container-title">{title}</p>
                <div className="entry-container">
                    {contents.map((entry, i) =>
                    <p className="bubble-text" key={i}>{entry}</p>
                    )}
                </div>
            </div>
        );
    }
}

export default BubbleContainer;
