import React from 'react'

const navItems = [
    {
        text: "About",
        anchor: "#about"
    },
    {
        text: "Experience",
        anchor: "#experience"
    },
    {
        text: "Projects",
        anchor: "#projects"
    }
]

function toggleMenuOff() {
    console.log("off")
    document.getElementById("checkbox_toggle").checked = false;
}

function Navigation() {
    return (
        <nav className="site-navigation">
            <img className="nav-logo" src="./images/sai-logo.png" alt="Sai Logo"></img>
            <ul className="nav-links">
                <input type="checkbox" id="checkbox_toggle"/>
                <label htmlFor="checkbox_toggle" className="hamburger">&#9776;</label>
                <div className="menu">
                    {navItems.map((item, i) =>
                    <li onClick={toggleMenuOff} key={i}><a className="menu-item" href={item.anchor}>{item.text}</a></li>
                    )}
                </div>
            </ul>
        </nav>
    );
}

export default Navigation;